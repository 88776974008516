import styled from 'styled-components';
import {device} from "../styles/styleUtls";

export const InputTextStyle = styled.input`
    border: none;
    background-color: ${({hasError}) => hasError ? '#e29c9c !important' : '#EBF3FF'};
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 2rem;
    padding: 0 15px;
    box-sizing: border-box;
    transition: all .2s .1s ease-out;
    
    &:focus {
    outline: none;
    background-color: white;
    }
    
    @media ${device.mobileL} {
      font-size: 1.8rem;
    }
`;