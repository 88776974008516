import {css, keyframes} from 'styled-components';

export const bottomToTopAnimation = keyframes`
  from {transform: translateY(0)}
  to   {transform: translateY(-30px)}
`;

export const fadeInAnimation = keyframes`
  from {opacity: 0}
  to   {opacity: 1}
`;

export const blurBackground = keyframes`
  0% {filter: blur(4px)}
  50% {filter: blur(7px)}
  100% {filter: blur(4px)}
`

export const setAnimation = (name, duration, delay=0, behavior="backwards", effects="linear") => css`
  animation: ${name} ${duration} ${delay} ${behavior} ${effects};
`