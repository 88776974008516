import React from "react";
import styled from 'styled-components';
import {fadeInAnimation, setAnimation} from "./animations";
import {device} from "./styleUtls";


const QuestionSection = ({className, left, right}) => (
  <div className={className}>
    <div className="left-container">
      {left}
    </div>
    <div className="right-container">
      {right}
    </div>
  </div>
);

export const StyledQuestion = styled(QuestionSection)`
    height: 360px;
    display: flex;
    justify-content: space-between;
    
    .left-container,
     .right-container {
      width: 45%;
      height: 100%;
      position: relative;
    }
    
    .question-params {
      opacity: 0;
      ${setAnimation(fadeInAnimation, '.5s', '1.5s', 'forwards')}
    }
    
    .question-form {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    
    .question-image {
      position: absolute;
      right: 0;
      background-color: white;
      border-radius: 8px;
      width: 450px;
      height: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      
      img {
        width: 90%;
      }
    }
    
    @media ${device.desktop} {
      flex-direction: column;
      align-items: center;
      height: auto;
      width: 100%;
      text-align: center !important;
      
     .left-container,
     .right-container,
      .question-image {
        height: auto;
        width: 70%;
        margin: 0 auto 30px auto;
     }
     
     .question-form {
       position: relative;
       
       & .btn__custom {
          top: 0;
       }
     }
     .right-container {
         width: 80%;
         margin: 0 auto;
     }
     
     .question-image {
        position: static;
        margin-top: 50px;
     }
     
     .question-form {
          margin-top: 60px
        }
        
     .question-params {
       text-align: left;
       width: 100%;
     }
    }
    
    @media ${device.mobileL} {
       .left-container,
       .right-container,
       .question-image {
          width:100% 
        }
    }
    
    @media ${device.mobileM} {
        .btn__custom {
          position: static;
          border-radius: 50px !important;
          transform: translateY(20px);
        }
    }
`;