import * as firebase from "firebase/app";
import 'firebase/database';
import setConfig from '../environments';

const environment = setConfig();

const api = firebase.initializeApp(environment);

export const addCandidate = async (data) => await api.database().ref().child('candidates').push(data,
    err => {
        if (err) {
          console.error(err);
        }
    });

export default api;