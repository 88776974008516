import styled, {css} from 'styled-components';
import {device} from "../styles/styleUtls";

export const PageTitleHuge = styled.p`
  font-size: 4.5rem;
  ${({center}) => center ? css`
    text-align: center;
` : ''}
  
  @media ${device.mobileL} {
    font-size: 3.5rem;
  }
`

export const PageTitleBig = styled.p`
  font-size: 3rem;
    ${({center}) => center ? css`
    text-align: center;
` : ''}
    
    @media ${device.mobileL} {
    font-size: 2.4rem;
  }
`