import React, {useCallback, useReducer} from 'react';
import appReducer from './store/reducers/appReducer';
import styled, {} from 'styled-components';
import {Logo} from "./components/Logo";
import logo from './assets/images/logo.png';
import Video from "./containers/Video";
import Question from "./containers/Question";
import {BrowserRouter, Switch, Redirect} from "react-router-dom";
import {GuardProvider, GuardedRoute} from 'react-router-guards';
import Register from "./containers/Register";
import {AppContext} from "./contexts/AppContext";
import ThankYouPage from "./containers/ThankYouPage";
import {blurBackground, setAnimation} from "./styles/animations";
import {device, sizeDevice} from "./styles/styleUtls";

const AppWrapper = styled.div`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  
  &:before {
  content: '01110110101101010101101010101010101010100111011010110101010110101010101010101010011101101011010101011010101010101010101001110110101101010101101010101010101010100111011010110101010110101010101010101010011101101011010101011010101010101010101001110110101101010101';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 8.5rem;
    word-break: break-all;
    z-index: -1;
    color: #46506B;
    opacity: .15;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: inset 1px 20px 20px 20px rgba(16,27,55,.5);
    animation: ${blurBackground} 5s infinite;
  }
  
  @media ${device.desktop} {
    width: ${sizeDevice.tablet};
  }
  
  @media ${device.laptop} {
    width: ${sizeDevice.mobileL};
    text-align: center;
  }
  
  @media ${device.mobileL} {
    width: 90%;
    
    &:before {
      border-radius: 0 !important;
    }
  }
`;

function App() {

    return (
            <AppWrapper>
                <Logo src={logo} alt="logo pic" />
                <Video/>
                <BrowserRouter>
                    <GuardProvider>
                        <Switch>
                            <GuardedRoute exact path='/' component={ThankYouPage} />
                            {/*<GuardedRoute exact path='/' component={Question} />*/}
                                {/*<GuardedRoute exact path='/register' component={Register} meta={{isAnswered: state.isAnsweredCorrect}} />*/}
                                {/*<GuardedRoute exact path='/thank-you' component={ThankYouPage} meta={{isAnswered: state.isAnsweredCorrect, isFormFilled: state.isFormFilled}} />*/}
                            <GuardedRoute path='**'><Redirect to='/'/></GuardedRoute>
                        </Switch>
                    </GuardProvider>
                </BrowserRouter>
            </AppWrapper>
    );
}

export default App;
