import React from "react";
import {PageTitleHuge} from "../components/Titles";
import {PageTextBig} from "../components/Texts";

const ThankYouPage = () => {

  return (
    <>
      <PageTitleHuge center>Thank you for your interest in the Dev Club!</PageTitleHuge>
      <br/>
      <PageTextBig center>Unfortunately, the registration is closed due to overwhelming demand.</PageTextBig>
    </>
  )
}

export default ThankYouPage