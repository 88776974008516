import React from "react";
import styled, {css} from 'styled-components';
import {fadeInAnimation, setAnimation} from "../styles/animations";

const Image = ({className, classNames,  src, alt, lazyLoading}) => (

  <div className={className}>
    <img className={classNames} src={src} alt={alt} loading={lazyLoading ? 'lazy' : 'egar'} />
  </div>
)

export const StyledImage = styled(Image)`
   & > img {
      ${({animateIn}) => animateIn ? css`
        opacity: 0;
        ${setAnimation(fadeInAnimation, ".3s", ".2s", "forwards", "ease-out")}
` : ''}
   }
`;