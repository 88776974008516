export const sizeDevice = {
  mobileS: '320px',
  mobileM: '475px',
  mobileL: '768px',
  tablet: '900px',
  laptop: '1024px',
  desktop: '1250px'
}

export const device = {
  mobileS: `(max-width: ${sizeDevice.mobileS})`,
  mobileM: `(max-width: ${sizeDevice.mobileM})`,
  mobileL: `(max-width: ${sizeDevice.mobileL})`,
  tablet: `(max-width: ${sizeDevice.tablet})`,
  laptop: `(max-width: ${sizeDevice.laptop})`,
  desktop: `(max-width: ${sizeDevice.desktop})`,
};