import styled from 'styled-components';
import {bottomToTopAnimation} from "./animations";

export const StyledRowField = styled.div`
   position:relative;
   margin-top: 20px;
   
   & > span.error-message {
      color: red;
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 15px;
      z-index: -1;
      animation: ${bottomToTopAnimation} .2s .2s forwards ease-out;
   }
`;