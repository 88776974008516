import styled, {css} from 'styled-components';
import {device} from "../styles/styleUtls";

export const PageTextBig = styled.p`
  font-size: 2.4rem;
  line-height: 1.6;
  text-align: ${({center}) => center ? 'center' : 'left'};
  @media ${device.mobileL} {
    font-size: 1.8rem;
  }
`;