import styled from 'styled-components';
import {device} from "./styleUtls";

export const VideoWrapperStyled = styled.div`
  width: 100%;
  height: 370px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 70px 0;
  
  h1 {
    font-family: 'Press Start 2P', cursive;
    font-size: 2.4rem;
    line-height: 1.6;
    margin: 30px 0;
  }
  
  iframe {
     cursor: pointer;
     width: 600px;
     height: 350px;
     border-radius: 10px;
     &:focus {
      outline: none;
     } 
  }
  
  @media ${device.laptop} {
    padding: 0 0 40px 0;
    
    iframe {
      width: 70%
    }
  }
  
  @media ${device.mobileL} {
    iframe {
        width: 90%
    }
  }
`;