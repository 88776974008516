import styled from 'styled-components';
import {device} from "../styles/styleUtls";

export const Logo = styled.img`
  width: 100px;
  position: absolute;
  top: 15px;
  left: 0;
  
  @media ${device.laptop} {
    position: static;
    width: 150px
  }
`;
