import styled from 'styled-components';

export const ButtonStyled = styled.button`
  cursor: pointer;
  border: none;
  background-color: #7c7f85;
  color: #ffffff;
  font-size: 1.8rem;
  border-radius: 50px;
  padding: 10px 30px;
  transition: background-color .2s .1s ease-out;
  
  &:focus {outline: none;}
  &:hover {
    background-color: #616267;
  }
  :disabled {
    background-color: aliceblue;
  }
`;
