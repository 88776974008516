const envs = new Map([
  [["localhost", "colmandev-staging.web.app", "colmandev-staging.firebaseapp.com"], {
    apiKey: "AIzaSyATwLEo4hdG4udRs1rDzYGVtPH_mjo-w7g",
    authDomain: "colmandev-staging.firebaseapp.com",
    databaseURL: "https://colmandev-staging.firebaseio.com",
    projectId: "colmandev-staging",
    storageBucket: "colmandev-staging.appspot.com",
    messagingSenderId: "862435898528",
    appId: "1:862435898528:web:554dc228fe3688ad3905c5",
    measurementId: "G-QJXLBDLQJS"
  }],
  [["colmandev-d798c.web.app", "colmandev-d798c.firebaseapp.com", "program.co.il"], {
    apiKey: "AIzaSyA-z1YxxDIby_rGN_zltYOnEYQQBd_OqPw",
    authDomain: "colmandev-d798c.firebaseapp.com",
    databaseURL: "https://colmandev-d798c.firebaseio.com",
    projectId: "colmandev-d798c",
    storageBucket: "colmandev-d798c.appspot.com",
    messagingSenderId: "902323357392",
    appId: "1:902323357392:web:a5b1fd8f6f582d7b7d00d5",
    measurementId: "G-KPFMHLE3YK"
  }]
]);

export default () => {
  const hostName = window.location.hostname;
  const keys = envs.keys();

  const currentEnv = Array.from(keys).find(keysArr => {
    return keysArr.find(key => {
      if (key === hostName) {
        return keysArr;
      }
    });
  });
  return envs.get(currentEnv);
}