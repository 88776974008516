import React from "react";
import {VideoWrapperStyled} from '../styles/VideoWrapperStyled';


const Video = () => <>
    <VideoWrapperStyled>
        <h1>Colman Dev Club</h1>
        <iframe src="https://www.youtube.com/embed/RmyvSoflOpM?autoplay=1" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture color"
                allowFullScreen />
    </VideoWrapperStyled>
</>

export default Video;