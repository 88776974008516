import React from "react";
import styled from 'styled-components'
import {device} from "./styleUtls";

const Register = ({className, content}) => (
  <div className={className}>
    {content}
  </div>
)

export const StyledRegister = styled(Register)`
  text-align: center;
  width: 600px;
  margin: 0 auto 40px auto;
  
  .register-form {
    form > div {
      margin-top: 40px;
    }
  }
  
  @media ${device.mobileL} {
     width: 90%;
  }
`;